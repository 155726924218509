import { useTranslation } from 'next-i18next';

import { mobileAppUrls } from '@/core/constants/appUrls';
import AndroidIcon from '@/core/images/android-icon.svg';
import AppleIcon from '@/core/images/apple-icon.svg';
import { ButtonLink } from '@/core/ui/components/Button/ButtonLink';

const iconClasses = 'w-4 h-4 self-center text-text-subtle';

export const DownloadAppButton = ({ isApple }: { isApple: boolean }) => {
  const { t } = useTranslation('layout');

  return (
    <ButtonLink
      href={isApple ? mobileAppUrls.iphone : mobileAppUrls.android}
      size="big"
      buttonStyle="outlined"
      className="mt-4 w-[158px]"
    >
      <div className="flex items-center gap-x-1">
        {isApple ? (
          <AppleIcon className={iconClasses} />
        ) : (
          <AndroidIcon className={iconClasses} />
        )}
        {t('Download now')}
      </div>
    </ButtonLink>
  );
};
