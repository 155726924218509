import { useCallback, useEffect, useRef, useState } from 'react';
import { Timeout } from 'react-number-format/types/types';

import { breakpoints } from '@lib/theme/config/breakpoints';
import { useIsIos } from '@lib/theme/hooks/useIsIos';
import { useIsSafariBrowser } from '@lib/theme/hooks/useIsSafariBrowser';
import { useMedia } from '@lib/theme/hooks/useMedia';

const timeout = 15000; // 15 seconds

export const useJushAd = () => {
  const timer = useRef<Timeout>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const isIos = useIsIos();
  const isSafari = useIsSafariBrowser();
  const isApple = isIos || isSafari;
  const { windowInnerWidth } = useMedia();
  const isMobile = Number(windowInnerWidth) < breakpoints.lg;

  const handleOpen = () => {
    setShouldAnimate(true);
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };
  const handleClose = () => {
    setIsExpanded(false);
  };

  const handleCloseIfExpanded = useCallback(() => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  }, [isExpanded]);

  const resetTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(handleCloseIfExpanded, timeout);
  }, [handleCloseIfExpanded]);

  // Close the ad if the user is inactive for 15 seconds
  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('click', handleActivity);

    resetTimer();

    return () => {
      clearTimeout(timer.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [resetTimer]);

  return {
    isMobile,
    isApple,
    isExpanded,
    shouldAnimate,
    handleOpen,
    handleClose,
  };
};
