import { X } from '@phosphor-icons/react';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';

import { BagsImage } from './components/BagsImage/BagsImage';
import { DownloadAppButton } from './components/DownloadAppButton/DownloadAppButton';
import { QrCodeImage } from './components/QrCodeImage/QrCodeImage';

export const JushAdExpandedContent = ({
  onClose,
  isApple,
  isMobile,
}: {
  onClose: () => void;
  isMobile: boolean;
  isApple: boolean;
}) => {
  const { t } = useTranslation('layout');
  return (
    <div className="w-[296px]">
      <div
        className={cn(
          'absolute left-4 right-2 z-10',
          isMobile ? 'bottom-3.5' : 'bottom-[73px]'
        )}
      >
        <div className="flex">
          <div>
            <h2 className="max-w-60 whitespace-pre-line text-balance text-left text-body-lg font-medium text-white">
              {t('Download the Żabka Jush app and shop big and express!')}
            </h2>
            {isMobile && <DownloadAppButton isApple={isApple} />}
          </div>
        </div>
        <button
          type="button"
          className="absolute right-0 top-0 flex size-10 items-center justify-center text-white"
          onClick={onClose}
        >
          <X size={DEFAULT_ICON_SIZES.lg} weight="bold" />
        </button>
      </div>
      {!isMobile && <QrCodeImage />}
      <BagsImage isMobile={isMobile} />
    </div>
  );
};
