import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';

import SpinnerIconSvg from '../svg/spinner-icon.svg';

import { AccessibleSvg } from './AccessibleSvg';

export const SpinnerIcon: FC<SpinnerProps> = ({ className, title }) => (
  <AccessibleSvg
    className={cn('animate-spin', className, 'text-primary-400')}
    title={title}
    SvgComponent={SpinnerIconSvg}
  />
);

type SpinnerProps = {
  className?: string;
  title: string;
};
