import { cn } from '@/core/ui/utils';

import { ButtonSize, ButtonStyle } from '../types/Button.type';

type Args = {
  size: ButtonSize;
  buttonStyle: ButtonStyle;
  isIcon: boolean;
};

export const createButtonStyles = ({ buttonStyle, size, isIcon }: Args) => {
  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isBig = size === 'big';

  const buttonStyleObject = {
    isPrimary: buttonStyle === 'primary',
    isOutlined: buttonStyle === 'outlined',
  };

  return cn(
    // Common styles
    'flex items-center justify-center rounded-xl text-body-md font-semibold transition-all',

    isSmall && 'h-8',
    isSmall && !isIcon && 'px-4 py-1.5',
    isSmall && isIcon && 'p-1.5',

    isMedium && 'h-10',
    isMedium && !isIcon && 'px-6 py-3',
    isMedium && isIcon && 'p-2.5',

    isBig && 'h-11',
    isBig && !isIcon && 'px-6 py-2.5',
    isBig && isIcon && 'p-3.5',

    buttonStyleObject.isPrimary &&
      cn(
        'bg-action-primary-default',
        'text-white',
        'hover:bg-action-primary-hovered',
        'focus:ring-4',
        'focus:ring-action-primary-focused',
        'active:bg-action-primary-pressed',
        'disabled:bg-action-primary-disabled',
        'disabled:text-disabled'
      ),
    buttonStyleObject.isOutlined &&
      cn(
        'bg-grayscale-0',
        'text-grayscale-750',
        'border',
        'border-grayscale-300',
        'hover:bg-grayscale-50',
        'focus:bg-grayscale-50',
        'focus:ring-4',
        'focus:ring-action-primary-focused',
        'active:bg-grayscale-50',
        'disabled:bg-grayscale-0',
        'disabled:text-grayscale-300'
      )
  );
};
