import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, forwardRef } from 'react';

import { cn } from '@/core/ui/utils';

import { ButtonContent } from './components/ButtonContent/ButtonContent';
import { createButtonStyles } from './helpers/createButtonStyles';
import { BaseButtonProps } from './types/Button.type';

type NextLink = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  LinkProps;

type ButtonLinkProps = NextLink & BaseButtonProps;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      buttonStyle = 'primary',
      size = 'medium',
      leftIcon,
      rightIcon,
      disabled: _,
      onClick,
      children,
      icon,
      testId,
      className,
      isLoading,
      loadingIndicatorTitle,
      ...rest
    },
    ref
  ) => (
    <Link
      ref={ref}
      className={cn(
        createButtonStyles({
          size,
          buttonStyle,
          isIcon: !!icon,
        }),
        className
      )}
      data-testid={testId}
      onClick={onClick}
      {...rest}
    >
      <ButtonContent
        icon={icon}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        isLoading={isLoading}
        loadingIndicatorTitle={loadingIndicatorTitle}
      >
        {children}
      </ButtonContent>
    </Link>
  )
);

ButtonLink.displayName = 'Button';
