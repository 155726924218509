import Image from 'next/image';
import Link from 'next/link';

import qrImage from '../../../../img/qr.png';

export const QrCodeImage = () => (
  <Link
    href="https://link.jush.pl/NG8m/webqrcode"
    target="_blank"
    className="z-10"
  >
    <Image
      src={qrImage}
      alt="QR code"
      className="absolute bottom-[-29px] right-4 size-[94px]"
    />
  </Link>
);
