import Image from 'next/image';

import bagDesktop from '../../../../img/bag-desktop.png';
import bagMobile from '../../../../img/bag-mobile.png';

export const BagsImage = ({ isMobile }: { isMobile: boolean }) => {
  if (isMobile) {
    return (
      <Image
        src={bagMobile}
        alt="Coupons image"
        className="absolute bottom-[-73px] right-0.5 w-[125px]"
      />
    );
  }

  return (
    <Image
      src={bagDesktop}
      alt="Coupons image"
      className="absolute -left-0.5 bottom-[-35px] w-[171px]"
    />
  );
};
